import React from 'react';

// Own components
import Icon from '../Icon/icon';

export const SocialNetworks = () => (
  <section id="social-network" className="social_section">
    <ul>
      <li className="linkedin social-section-tab">
        <a href="https://www.linkedin.com/company/protecno-ingenieria-s-a-s" rel="noopener noreferrer" target="_blank" title="Check us out on Instagram">
          <img data-src="/images/social/linkedin-backgound.jpeg" alt="instagram" className="lazyload" />
          <div className="social-tab-detail social_icon_container">
            <Icon icon="linkedin social_icon" />
            <h2>Linkedin</h2>
          </div>
          <div className="description" />
        </a>
      </li>
      <li className="instagram social-section-tab">
        <a href="https://www.instagram.com/protecnoingenieria/" rel="noopener noreferrer" target="_blank" title="Check us out on Instagram">
          <img data-src="/images/social/instagram-backgound.jpeg" alt="instagram" className="lazyload" />
          <div className="social-tab-detail social_icon_container">
            <Icon icon="instagram social_icon" />
            <h2>Instagram</h2>
          </div>
          <div className="description" />
        </a>
      </li>
      <li className="you_tube social-section-tab">
        <a href="https://www.youtube.com/channel/UCpO6V6PLe3vdQwBYHnL1qug" rel="noopener noreferrer" target="_blank" title="Check us out on YouTube">
          <img data-src="/images/social/youtube-background.jpeg" alt="youtube" className="lazyload" />
          <div className="social-tab-detail social_icon_container">
            <Icon icon="youtube-square social_icon" />
            <h2>YouTube</h2>
          </div>
          <div className="description" />
        </a>
      </li>
      <li className="facebook social-section-tab">
        <a href="https://www.facebook.com/protecnoing/" rel="noopener noreferrer" target="_blank" title="Check us out on Facebook">
          <img data-src="/images/social/facebook-background.jpeg" alt="facebook" className="lazyload" />
          <div className="social-tab-detail social_icon_container">
            <Icon icon="facebook-square social_icon" />
            <h2>Facebook</h2>
          </div>
          <div className="description" />
        </a>
      </li>
      <li className="twitter social-section-tab">
        <a href="https://twitter.com/Protecno_Ing" target="_blank" rel="noopener noreferrer" title="Check us out on Twitter">
          <img data-src="/images/social/twitter-background.jpeg" alt="twitter" className="lazyload" />
          <div className="social-tab-detail social_icon_container">
            <Icon icon="twitter-square social_icon" />
            <h2>Twitter</h2>
          </div>
          <div className="description" />
        </a>
      </li>
    </ul>
  </section>
);
import React from 'react';
import PropTypes from 'prop-types';

export const MediaTag = ({ id, type, url, height, className = 'img-responsive' }) => {
  if (type === 'image') return (
    <img
      src={url}
      data-src={url}
      alt={id}
      className={`${className} lazyload`}
    />
  );

  let style = {};
  if (height === 175) style = { pointerEvents: 'none' };

  return (
    <iframe
      width="100%"
      height={height}
      src={`${url}?autoplay=0&fs=0&loop=1&modestbranding=1&showinfo=0&rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={style}
     />
  )
};

MediaTag.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['image', 'video']).isRequired,
  url: PropTypes.string.isRequired,
  height: PropTypes.number,
  className: PropTypes.string,
};
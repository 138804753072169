import React from 'react';
import { FormattedMessage } from 'react-intl';

// Own components
import { MediaTag } from '../../components/MediaTag';
import { SocialNetworks } from '../../components/SocialNetworks';
import SectionCover from '../../components/SectionCover/sectionCover';

// Assets & Misc
import { CustomLink } from '../../utils/navigation';
import { multimedia } from '../../constants/multimedia';
import { useLanguages } from '../../hooks/useLanguages';

const ComingSoonMessage = () => {
  const { path } = useLanguages();

  return (
    <div className="comingsoon-page">
      <CustomLink to={path} className="logo">
        <img data-src="/images/protecno.png" className="img-responsive lazyload" alt="logo" style={{ width: 300 }} />
      </CustomLink>
      <FormattedMessage id="comingSoon" tagName="h2"/>
      <CustomLink to={path} className="header-requestbtn learn-more-btn home-link hvr-bounce-to-right">
        <FormattedMessage id="backToHome"/>
      </CustomLink>
    </div>
  );
};

export const Multimedia = () => {
  if (multimedia.length === 0) return <ComingSoonMessage />;

  return (
    <>
      <SectionCover title="Multimedia" src="/images/multimedia/multimedia-cover.jpeg"/>
      <section className="portfoio-section" style={{ marginTop: '2rem' }}>
        <div className="container">
          <div className="portfolio-section port-col project_classic portfolio-2">
            <div className="isotopeContainer">
              {multimedia.map(({ id, url, title, description }) => (
                <div key={id} className="col-lg-6 col-md-6 col-sm-6 col-xs-12 img mbot30 isotopeSelector manufacturing ">
                  <MediaTag type="video" height={430} url={url} />
                  <div className="marbtm30" />
                  {title && <FormattedMessage id={title} tagName="h4" />}
                  {description && <FormattedMessage id={description} tagName="p" />}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <SocialNetworks />
    </>
  );
};

// The media id must be unique in the array
/*
  {
    id: 'media-1',
    url: 'https://www.youtube.com/embed/Z3w5gVM_4y8',
    title: 'title',
    description: 'Description'
  },
*/
export const multimedia = [
  {
    id: 'media-0',
    url: 'https://www.youtube-nocookie.com/embed/g1Veaez9TE8'
  },
  {
    id: 'media-1',
    url: 'https://www.youtube-nocookie.com/embed/vfxNoxTu7C4',
  },
  {
    id: 'media-2',
    url: 'https://www.youtube-nocookie.com/embed/8t_AKYZkp44',
  },
  {
    id: 'media-3',
    url: 'https://www.youtube-nocookie.com/embed/wibtkJ7GhSk',
  },
  {
    id: 'media-4',
    url: 'https://www.youtube-nocookie.com/embed/hn24NJuJmFI',
  },
  {
    id: 'media-5',
    url: 'https://www.youtube-nocookie.com/embed/tB2Sdi0FJqo',
  },
  {
    id: 'media-6',
    url: 'https://www.youtube-nocookie.com/embed/PNkk_BI3Urc',
  },
  {
    id: 'media-7',
    url: 'https://www.youtube-nocookie.com/embed/T5p8KIrWz8Y',
  },
];
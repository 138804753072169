import React from 'react';

// Own components
import Layout from './layout';
// Translation
import messages from '../../i18n/es';

const EnLayout = (props) => (
  <Layout
    {...props}
    i18nMessages={messages}
  />
);

export default EnLayout;
